import * as React from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';
import Link from './link';
import config from '../../config';
import Loadable from 'react-loadable';
import LoadingProvider from './mdxComponents/loading';

const LoadableComponent = Loadable({
  loader: () => import('./search/index'),
  loading: LoadingProvider,
});

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 48px;
  background: rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(15, 34, 67, 0.11);
  z-index: 100;
`;

const TabsWidth = styled('div')`
  margin: 0 auto;
  width: 60%;
  display: flex;
  justify-content: space-around;
  a {
    display: block;
    position: relative;
    height: 48px;
    line-height: 48px;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    color: rgba(21, 22, 24, 0.72);
    overflow: hidden;
    &:hover,
    &.active {
      color: #3e6cff;
    }
    &.active::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -2px;
      left: 50%;
      width: 20px;
      height: 6px;
      background: #3e6cff;
      border-radius: 3px;
      transform: translateX(-50%);
    }
  }
`;

const TopbarLayout = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                title
                slug
                order
              }
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      const url = {},
        links = [];
      allMdx.edges.sort((a, b) => a.node.fields.order - b.node.fields.order);
      allMdx.edges.map((item) => {
        const paths = item.node.fields.slug.split('/');
        if (paths.length > 2) {
          if (!url[paths[1]]) {
            url[paths[1]] = item.node.fields.slug;
          }
        } else if (paths.length === 2 && paths[1] !== '') {
          links.push({
            name: item.node.fields.title,
            slug: paths[1],
          });
        }
      });
      return (
        <Wrapper>
          <TabsWidth>
            {links.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={url[item.slug]}
                  className={
                    location.pathname.startsWith(config.gatsby.pathPrefix + '/' + item.slug) ||
                    location.pathname.startsWith(config.gatsby.pathPrefix + item.slug)
                      ? 'active'
                      : ''
                  }
                >
                  {item.name}
                </Link>
              );
            })}
          </TabsWidth>
          <LoadableComponent />
        </Wrapper>
      );
    }}
  />
);

export default TopbarLayout;
