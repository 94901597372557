const ENV = typeof window !== 'undefined' ? window.ENV : process.env;
const config = {
  gatsby: {
    pathPrefix:
      ENV.NODE_ENV_LANGURAGE !== 'en-us' && ENV.NODE_ENV_LANGURAGE !== 'ja-jp'
        ? `/${ENV.NODE_ENV_LANGURAGE}`
        : '/',
    siteUrl:
      ENV.NODE_ENV_LANGURAGE === 'en-us'
        ? 'https://academy.viture.com'
        : ENV.NODE_ENV_LANGURAGE === 'ja-jp'
        ? 'https://academy.viture.jp'
        : `https://academy.viture.com/${ENV.NODE_ENV_LANGURAGE}/`,
    gaTrackingId: null,
    trailingSlash: false,
    lang: ENV.NODE_ENV_LANGURAGE,
  },
  header: {
    logo: 'https://s1.viture.com/viture_com/images/viture-logo.svg',
    logoLink: 'https://www.viture.com/',
    title: '',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: ``,
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: 'search',
    },
    hasLinks: ENV.NODE_ENV_LANGURAGE === 'en-us',
  },
  sidebar: {
    forcedNavOrder: [],
    collapsedNav: [],
    links: [],
    frontLine: false,
    ignoreIndex: true,
    title: '',
    pageActions: ENV.NODE_ENV_LANGURAGE === 'en-us',
  },
  siteMetadata: {
    title: 'VITURE Academy',
    description: 'VITURE Academy',
    ogImage: '/viture-og.jpg',
    docsLocation: 'https://academy.viture.com',
    favicon: '/favicon.png',
    bodyFontName:
      ENV.NODE_ENV_LANGURAGE === 'en-us'
        ? 'Outfit'
        : ENV.NODE_ENV_LANGURAGE === 'ja-jp'
        ? 'Noto Sans JP, Outfit'
        : 'Noto Sans SC, Outfit',
    documentFontName:
      ENV.NODE_ENV_LANGURAGE === 'en-us'
        ? 'Inter'
        : ENV.NODE_ENV_LANGURAGE === 'ja-jp'
        ? 'Noto Sans JP, Inter'
        : 'Noto Sans SC, Inter',
  },
  pwa: {
    enabled: false,
    manifest: {
      name: 'VITURE Academy',
      short_name: 'VITURE Academy',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
