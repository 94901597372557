import * as React from 'react';
import Link from './link';
import config from '../../config';
import logoImg from './images/viture-logo.svg';

const Header = () => (
  <div className={'navBarWrapper'}>
    <nav className={'navBarDefault'}>
      {config.header.hasLinks && (
        <a href="https://www.viture.com/experience" target="_blank" className={'navBarBrand'}>
          EXPERIENCE
        </a>
      )}
      <div className={'navBarHeader'}>
        {config.header.hasLinks ? (
          <Link to={'https://www.viture.com/'}>
            <img
              className={'img-responsive displayInline'}
              src={config.header.logo !== '' ? config.header.logo : logoImg}
              alt={'logo'}
            />
          </Link>
        ) : (
          <img
            className={'img-responsive displayInline'}
            src={config.header.logo !== '' ? config.header.logo : logoImg}
            alt={'logo'}
          />
        )}
      </div>
      {config.header.hasLinks && (
        <a href="https://www.viture.com/store" target="_blank" className={'navBarBrand'}>
          ORDER NOW
        </a>
      )}
    </nav>
  </div>
);

export default Header;
