import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql, navigate } from 'gatsby';
import Link from './link';
import Arrow from './images/opened';

const Wrapper = styled('div')`
  display: none;
  position: relative;
  padding: 0.16rem 0.2rem;
  width: 100%;
  height: 1.18rem;
  white-space: nowrap;
  background: #f1f3f9;
  .root_tabs {
    overflow: auto hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .root {
    display: inline-block;
    margin-right: 0.08rem;
    padding: 0 0.24rem;
    height: 0.4rem;
    line-height: 0.36rem;
    font-weight: 500;
    font-size: 0.14rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    border: 0.015rem solid rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    &.active {
      color: #ffffff;
      background: #3e6cff;
      border: 0.015rem solid #3e6cff;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const Select = styled('div')`
  position: absolute;
  top: 0.68rem;
  left: 0.2rem;
  right: 0.2rem;
  background: #f1f3f9;
  border: 0.015rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.08rem;
  overflow: hidden;
  z-index: 9999;
  &.active {
    box-shadow: 0 0.08rem 0.24rem -0.06rem rgba(4, 8, 16, 0.15);
  }
  .path {
    display: block;
    position: relative;
    padding: 0 0.58rem 0 0.12rem;
    height: 0.5rem;
    line-height: 0.5rem;
    font-weight: 500;
    font-size: 0.18rem;
    color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    svg {
      position: absolute;
      top: 0.16rem;
      right: 0.12rem;
      width: 0.18rem;
      height: 0.18rem;
      transition: transform 0.2s ease;
    }
    &.active svg {
      transform: rotate(180deg);
    }
  }
  .list {
    display: none;
    background: #ffffff;
    overflow: hidden;
    &.showed {
      display: block;
    }
    .list-content {
      padding: 0.16rem;
    }
    .link {
      display: block;
      padding: 0.16rem 0.1rem;
      font-weight: 500;
      font-size: 0.16rem;
      line-height: 1.3;
      color: rgba(0, 0, 0, 0.6);
      border-bottom: 0.01rem solid rgba(15, 34, 67, 0.05);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &.active {
        font-weight: 600;
        color: #3e6cff;
        background: rgba(62, 108, 255, 0.1);
      }
    }
  }
`;

const Menu = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                slug
                order
                title
              }
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      const links = [];
      allMdx.edges.sort((a, b) => a.node.fields.order - b.node.fields.order);
      allMdx.edges.map((item) => {
        if (item.node.fields.slug !== '/') {
          const paths = item.node.fields.slug.split('/');
          links.push({
            name: item.node.fields.title,
            slug: item.node.fields.slug,
            level: paths.length - 1,
          });
        }
      });
      const [selectLinks, setSelectLinks] = useState([]);
      const [isOpened, setOpened] = useState(false);
      const [activeIndex, setActiveIndex] = useState(0);
      const rootLinks = links.filter((item) => item.level === 1);
      useEffect(() => {
        const rootLink = rootLinks.filter((item) => location.pathname.includes(item.slug));
        if (rootLink && rootLink.length) {
          const children = links.filter(
            (item) => item.slug !== rootLink[0].slug && item.slug.includes(rootLink[0].slug)
          );
          children.forEach((item, index) => {
            if (location.pathname.includes(item.slug)) {
              setActiveIndex(index);
            }
          });
          setSelectLinks(children);
        }
      }, [location.pathname]);
      return (
        <Wrapper>
          <div className="root_tabs">
            {rootLinks.map((item, index) => (
              <div
                key={index}
                className={`root ${location.pathname.includes(item.slug) ? 'active' : ''}`}
                onClick={() => {
                  const children = links.filter(
                    (child) => child.slug !== item.slug && child.slug.includes(item.slug)
                  );
                  if (children.length) {
                    navigate(children[0].slug);
                  }
                }}
              >
                {item.name}
              </div>
            ))}
          </div>
          <Select className={isOpened ? 'active' : ''}>
            <a className={`path ${isOpened ? ' active' : ''}`} onClick={() => setOpened(!isOpened)}>
              {selectLinks && selectLinks[activeIndex] && (
                <span>{selectLinks[activeIndex].name || ''}</span>
              )}
              <Arrow />
            </a>
            <div className={`list ${isOpened ? ' showed' : ''}`}>
              <div className="list-content">
                {selectLinks.map((item, index) => (
                  <div
                    key={index}
                    className={`link ${location.pathname.includes(item.slug) ? 'active' : ''}`}
                    onClick={() => {
                      setOpened(!isOpened);
                      navigate(item.slug);
                    }}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          </Select>
        </Wrapper>
      );
    }}
  />
);

export default Menu;
