import styled from '@emotion/styled';

export const StyledActions = styled('div')`
  margin: 0;
  padding: 0;
  width: auto;
  display: grid;
  grid-template-rows: 120px;
  column-gap: var(--side-padding);
  grid-template-columns: calc(50% - var(--side-padding) / 2) calc(50% - var(--side-padding) / 2);

  .previousBtn,
  .nextBtn {
    -moz-box-align: center;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
    margin: 0;
    padding: 0 30px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    place-self: stretch;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    text-decoration: none;
  }

  img {
    width: 100%;
  }

  .preRightWrapper,
  .nextRightWrapper {
    flex-grow: 1;
    margin-left: 28px;
  }

  .leftArrow {
    flex-shrink: 0;
    width: 49px;
    height: 33px;
  }

  .rightArrow {
    flex-shrink: 0;
    padding: 0 3.5px;
    width: 49px;
    height: 37px;
  }

  a {
    font-weight: 360;
    font-size: var(--desc-font-size);
    line-height: 1.64;
    letter-spacing: 0.01em;
    text-decoration: none;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
  }

  .nextPreviousTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #ff5f34;
  }

  .smallContent {
    margin-top: 6px;
    font-weight: 360;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.6);
  }
  @media screen and (max-width: 1280px) {
    display: block;
    .nextBtn {
      margin-top: var(--side-padding);
    }
    .previousBtn,
    .nextBtn {
      padding: 0 16px;
      height: 96px;
    }
  }
  @media screen and (max-width: 768px) {
    .previousBtn,
    .nextBtn {
      padding: 0 0.16rem;
      height: 0.96rem;
    }
    .nextBtn {
      margin-top: 0.12rem;
    }
    .preRightWrapper,
    .nextRightWrapper {
      margin-left: 0.18rem;
    }
    .rightArrow {
      padding: 0;
      width: 0.42rem;
    }
    a {
      font-size: 0.14rem;
    }
    .nextPreviousTitle {
      font-size: 0.18rem;
      line-height: 0.24rem;
    }
    .smallContent {
      margin-top: 0.09rem;
      font-size: 0.14rem;
      line-height: 0.17rem;
    }
  }
`;
