import { css } from '@emotion/react';
import heading4 from '../images/heading4.svg';
import config from '../../../config';

export const baseStyles = css`
  :root {
    --title1-font-size: 30px;
    --title2-font-size: 24px;
    --content-font-size: 16px;
    --desc-font-size: 12px;
    --side-padding: 32px;
    --main-width: 920px;
    --side-width: 280px;
  }
  @media screen and (max-width: 768px) {
    :root {
      --title1-font-size: 24px;
      --title1-font-size-m: 0.24rem;
      --title2-font-size-m: 0.2rem;
      --content-font-size-m: 0.16rem;
      --desc-font-size-m: 0.12rem;
      --side-padding-m: 0.2rem;
    }
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-display: swap;
    -webkit-touch-callout: none; /*系统默认菜单被禁用*/
    -webkit-user-select: none; /*webkit浏览器*/
    -khtml-user-select: none; /*早起浏览器*/
    -moz-user-select: none; /*火狐浏览器*/
    -ms-user-select: none; /*IE浏览器*/
    user-select: auto; /*用户是否能够选中文本*/
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: #c2c2c2;
  }

  :-ms-input-placeholder {
    /* Internet Explorer */
    color: #c2c2c2;
  }

  ::placeholder {
    color: #c2c2c2;
  }

  html {
    font-size: 100px;
  }

  body {
    font-family: ${config.siteMetadata.bodyFontName}, sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 16px;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  body.dark {
    background: #171717;
  }

  a {
    transition: color 0.15s;
    /* color: #663399; */
  }

  .app {
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100vh;
    overflow: hidden;
  }
  @media print {
    .app {
      height: auto;
    }
  }

  .visibleMobile {
    display: none;
  }
  .visibleMobileView {
    display: none !important;
  }
  .video-responsive {
    position: relative;
    margin: 0 0 24px;
    padding-bottom: 56.2%;
  }
  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  .displayInline {
    display: inline-block;
  }
  .navBarToggle {
    border: 0px solid #fff;
    border-radius: 4px;
    width: 36px;
    height: 33px;
    position: absolute;
    right: 20px;
    padding: 8px 5px;
    display: none;
  }
  .navBarToggle .iconBar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto;
    margin-top: 4px;
    background-color: #001934;
  }
  .navBarToggle .iconBar:first-of-type {
    margin-top: 0px;
  }
  .video-responsive iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .diffNewLine {
    color: #22863a;
    background-color: #f0fff4;
  }

  .diffRemoveLine {
    color: red;
    background-color: #ffcccc;
  }
  .navBarParent {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
  }
  .divider {
    height: 30px;
    margin: 0 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
  .navBarULRight {
    /* position: absolute;
  right: 30px; */
  }
  .githubIcon {
    width: 15px;
    margin-right: 5px;
  }

  .githubSection {
    display: flex;
    align-items: center;
    color: #000;
    opacity: 0.7;
  }

  .githubSection:hover {
    text-decoration: none;
    opacity: 1;
  }

  .navbar-default .navbar-toggle .icon-bar {
    background-color: #fff !important;
  }

  .navbar-default .navbar-toggle:focus,
  .navbar-default .navbar-toggle:hover {
    background-color: #001933;
  }

  .headerWrapper {
    border-bottom: 1px solid rgb(212, 218, 223);
    box-shadow: rgba(116, 129, 141, 0.1) 0px 1px 1px 0px;
    display: flex;
    align-items: center;
  }
  .formElement {
    background-color: transparent;
    padding: 4px;
    border-radius: 5px;
    position: relative;
  }
  .formElement:focus {
    outline: none;
    border: none;
  }
  .formElement svg path {
    fill: #2fd2c5;
  }
  .searchInput {
    width: 100%;
    background-color: rgba(28, 211, 198, 0.12) !important;
    border-width: 0 !important;
    color: #c2c2c2;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    opacity: 0.6;
    padding-left: 38px;
    max-width: 600px;
  }
  .searchInput:focus,
  .searchInput:visited,
  .searchInput:hover,
  .searchInput:focus-within {
    outline: none;
    border: 0;
  }
  .searchWrapper {
    padding-left: 0px;
    padding-right: 20px;
    flex: 1;
    position: relative;
  }
  .searchWrapper a {
    font-weight: 500;
  }
  .hitWrapper {
    background-color: #fff;
    padding: 0.7em 1em 0.4em;
    border-radius: 4px;
    position: absolute;
    width: 80vw;
    max-width: 30em;
    top: 40px;
    border: 1px solid #ccc;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    height: auto;
    max-height: 80vh;
    overflow: scroll;
    left: 0;
  }
  .hitWrapper ul li {
    margin-top: 0.7em;
    padding-top: 0.7em;
    border-top: 1px solid;
    list-style-type: none;
  }
  .hitWrapper ul li:first-of-type {
    border-top: 0px;
    margin-top: 0px;
    color: black !important;
    padding: 0px;
  }
  .showResults {
    display: block;
  }
  .hideResults {
    display: none;
  }
  .hitWrapper span {
    color: black;
    font-size: 14px;
  }
  .headerTitle {
    height: auto;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 300;
    color: #fff !important;
    margin-top: 16px;
    text-transform: uppercase;
  }
  .headerTitle a {
    color: #fff;
  }

  .headerTitle a:hover {
    text-decoration: none;
    opacity: 0.8;
  }
  .logoWrapper {
    padding: 21px 0;
    padding-left: 20px;
  }

  .logoContent {
    margin-left: 16px;
    font-size: 28px;
    line-height: 1.5;
    font-weight: 500;
    padding-right: 10px;
  }

  /* Header section starts here */
  .removePadd {
    padding: 0 !important;
  }
  .navBarDefault {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 0;
    padding: 0 30px;
    width: 100%;
    height: 80px;
    background-color: #000000;
    z-index: 1;
  }
  .navBarHeader {
    margin: 0 auto;
    text-align: center;
  }
  .navBarBrand {
    display: block;
    position: relative;
    padding: 0;
    line-height: 25px;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.165em;
    color: #ffffff;
  }
  .navBarBrand::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: transform 0.5s ease-in;
    transform: scaleX(0);
    transform-origin: 0% 50%;
  }

  .navBarBrand:hover::after {
    transform: scaleX(1);
  }

  .navBarUL li {
    list-style-type: none;
  }
  .navBarUL {
    -webkit-overflow-scrolling: touch;
  }
  .navBarUL li a {
    color: #fff !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 1em;
    opacity: 1;
    padding: 10px 15px;
  }
  .navBarNav {
    display: flex;
    align-items: center;
  }
  .navBarUL li a img,
  .navBarUL li a .shareIcon {
    width: 20px;
  }
  .navBarUL li a:hover {
    opacity: 0.7;
  }
  pre {
    border: 0 !important;
    background-color: rgb(245, 247, 249); /* !important; */
  }

  blockquote {
    color: rgb(116, 129, 141);
    margin: 0px 0px 24px;
    padding: 0px 0px 0px 12px;
    border-left: 4px solid rgb(230, 236, 241);
    border-color: rgb(230, 236, 241);
  }
  .socialWrapper {
    display: flex;
    align-items: center;
  }
  .socialWrapper li {
    display: inline-block;
  }
  .socialWrapper li a {
    display: contents;
  }
  .discordBtn,
  .twitterBtn {
    border-radius: 4px;
    border: solid 1px #d1d2d3;
    background-color: #f1f5f8;
    width: 20px;
    height: 20px;
    padding-top: 2px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    cursor: pointer;
  }
  .twitterBtn img {
    width: 12px !important;
  }
  .discordBtn img {
    width: 10px !important;
  }
  .discordBtn:hover,
  .twitterBtn:hover {
    opacity: 1;
  }
  .discordBtn {
    img {
      width: 10px;
    }
  }
  /* Header section ends here */
  .sidebarTitle {
    /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); */
    background-color: #f8f8f8;
    padding: 18px 16px;
    font-size: 18px;
    font-weight: 600;
    color: #001934;
    display: flex;
    align-items: center;
  }

  .sideBarShow {
    display: none;
  }

  .sidebarTitle a {
    color: #001934;
  }

  .greenCircle {
    width: 8px;
    height: 8px;
    background-color: #1cd3c6;
    border-radius: 50%;
    margin: 0 12px;
  }

  .headerNav {
    padding: 0px 24px;
    color: #001933;
    font-size: 16px;
    font-weight: 500;
    line-height: 1em;
  }

  .headerNav a {
    color: #001933;
    text-decoration: none;
  }

  .headerNav a:hover {
    text-decoration: none;
  }

  .logoWrapper img {
    width: 40px;
  }

  .sideBarUL {
    .item {
      position: relative;
      list-style: none;
      line-height: 20px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.01em;
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 66px;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.8);
        /* overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; */
      }
      &.active {
        > a {
          color: #2e70ff;
          background: rgba(62, 108, 255, 0.1);
          border-radius: 4px;
        }
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          width: 7px;
          height: 18px;
          background: #2e70ff;
          border-radius: 12px;
          transform: translateY(-50%);
        }
      }
    }
    .directory {
      padding-right: 42px;
      cursor: pointer;
    }
    & > li > ul > li > ul > li {
      position: relative;
      list-style-type: none;
      margin-bottom: 12px;
      width: auto;
      background: rgba(255, 255, 255, 0.3);
      border: 0.5px solid rgba(110, 110, 110, 0.5);
      border-radius: 4px;
      overflow: hidden;
      &.item {
        line-height: 140%;
        font-size: 16px;
        &.item.active::before {
          left: -4px;
        }
        &:hover {
          background: rgba(178, 185, 195, 0.2);
        }
      }
      a,
      .directory {
        padding: 0 42px 0 24px;
      }
      & > ul {
        padding: 0 24px 24px;
        & > li.item {
          font-weight: 500;
          font-size: var(--content-font-size);
          line-height: 1.2;
          a {
            padding: 8px 14px;
          }
          &:hover {
            background: rgba(178, 185, 196, 0.3);
            border-radius: 4px;
          }
          &.active:hover {
            background: unset;
          }
          &.item.active::before {
            left: -28px;
          }
        }
      }
      .collapser {
        position: absolute;
        top: 28px;
        right: 24px;
        width: 18px;
        height: 18px;
        background: transparent;
        border: none;
        transition: transform 0.3s cubic-bezier(0.83, 0, 0.17, 1);
        outline: none;
        z-index: 1;
        cursor: pointer;
        &.closed {
          transform: rotate(-90deg);
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .firstLevel ul li .collapser svg path {
    fill: #000000 !important;
  }
  .active .collapser > svg > path {
    fill: #001933 !important;
  }

  .showFrontLine .item > a:hover {
    background-color: #001933;
  }

  .showFrontLine .active > a {
    /* color: #fff; */
    background-color: #001933;
  }

  .firstLevel > ul > .item {
    margin-left: 0 !important;
  }

  .showFrontLine .item .item {
    border-left: 1px solid #e6ecf1;
    border-left-color: rgb(230, 236, 241);
    padding: 0;
    width: calc(100% - 16px) !important;
  }

  .showFrontLine .item .active > a {
    border-color: rgb(230, 236, 241) !important;
    border-style: solid none solid solid;
    border-width: 1px 0px 1px 1px;
    background-color: #1ed3c6 !important;
    color: #fff;
  }

  .titleWrapper {
    img {
      width: 100%;
      border-radius: 8px;
    }
  }

  .gitBtn {
    height: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
  }

  .gitBtn img {
    width: 15px;
    display: inline-block;
    margin-right: 5px;
  }

  .addPaddTopBottom {
    margin-top: 32px;
  }

  /* **************************** */

  /* tables.css */
  .table_wrapper {
    margin: 16px -8px 8px;
    overflow-x: auto;
  }

  table {
    padding: 0;
    border-spacing: 8px;
  }

  table tr {
    margin: 0;
    padding: 0;
  }

  /* table tr:nth-of-type(2n) {
    background-color: #f8f8f8;
  } */

  table tr th {
    margin: 0;
    padding: 8px;
    font-weight: unset;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  table tr td {
    margin: 0;
    padding: 8px;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  table tr th :first-of-type,
  table tr td :first-of-type {
    margin-top: 0;
  }

  table tr th :last-child,
  table tr td :last-child {
    margin-bottom: 0;
  }
  /* end - tables.css */

  /* Image styling */
  img {
    max-width: 100%;
  }
  /* end image */
  .githubBtn {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 10px 0px;
    padding-left: 15px;
    max-height: 40px;
  }
  .githubBtn span span {
    display: flex;
    align-items: center;
  }

  .communitySection {
    font-size: 24px;
    font-weight: 700;
  }
  .authorSection {
    padding: 20px 0;
  }
  .authorSection,
  .authorName {
    display: flex;
    align-items: center;
  }
  .authorImg img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    min-width: 75px;
    max-width: 75px;
    min-height: 75px;
    max-height: 75px;
  }
  .authorDetails {
    padding-left: 10px;
  }
  .authorDesc {
    padding-top: 5px;
    font-size: 14px;
  }
  .authorName img {
    margin-left: 10px;
    display: inline-block;
    width: 20px;
  }
  .authorName img:hover {
    opacity: 0.7;
  }

  .heading1 {
    margin: 36px 0 8px;
    font-size: var(--title1-font-size);
    font-weight: 700;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.9);
  }

  .heading2 {
    margin: 36px 0 8px;
    font-size: var(--title2-font-size);
    font-weight: 600;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.8);
  }

  .heading3 {
    margin: 16px 0 8px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.8);
  }

  .heading4 {
    margin: 16px 0 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.8);
    &::before {
      content: '';
      display: inline-block;
      margin: 7px 8px 0;
      width: 13px;
      height: 7px;
      background: url(${heading4}) no-repeat;
      vertical-align: top;
    }
  }

  .heading5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  .heading6 {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 16px;
    margin-top: 32px;
  }

  .paragraph {
    margin: 8px 0;
    font-family: ${config.siteMetadata.documentFontName}, sans-serif;
    font-weight: 400;
    font-size: var(--content-font-size);
    line-height: 1.4;
    color: rgba(0, 0, 0, 0.8);
    img {
      display: block;
      margin: 0 0 24px;
      border-radius: 12px;
    }
  }

  .pre {
    font-size: 14px;
    margin: 0px;
    padding: 16px;
    overflow: auto;
  }

  .unorderedList {
    margin: 8px 0;
    font-family: ${config.siteMetadata.documentFontName}, sans-serif;
    .listItem {
      position: relative;
      margin: 8px 0 0;
      padding-left: 28px;
      line-height: 1.4;
      font-weight: 400;
      font-size: var(--content-font-size);
      color: rgba(0, 0, 0, 0.8);
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 8px;
        left: 11px;
        width: 6px;
        height: 6px;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 50%;
      }
    }
  }

  .orderedList {
    margin: 8px 0;
    font-family: ${config.siteMetadata.documentFontName}, sans-serif;
    font-weight: 400;
    font-size: var(--content-font-size);
    color: rgba(0, 0, 0, 0.8);
    .listItem {
      position: relative;
      margin: 6px 0 0;
      line-height: 140%;
      counter-increment: listItem;
      &::before {
        content: counter(listItem) '.';
        margin-right: 4px;
        font-size: var(--content-font-size);
      }
    }
  }

  .listItem {
    img {
      margin: 24px 0;
      border-radius: 16px;
    }
  }

  .poweredBy {
    font-size: 0.6em;
    text-align: end;
    padding: 0;
  }
  .topnav {
    -webkit-transition: top 0.5s, bottom 0.5s;
  }

  @media screen and (max-width: 768px) {
    .app {
      display: block;
      height: auto;
      overflow: auto;
    }

    .formElement svg path {
      fill: #001934;
    }
    .visibleMobileView {
      display: block !important;
    }
    .searchInput {
      color: #001934;
    }
    .socialWrapper {
      position: absolute;
      right: 10px;
      top: 29px;
    }
    .responsive {
      margin-top: 15px;
      position: relative;
      padding-bottom: 20px;
      border-top: 1px solid #fff;
    }
    .headerTitle {
      padding-right: 50px;
      font-size: 16px;
    }
    .topnav.responsive .visibleMobile {
      display: block;
    }
    .topnav .navBarUL {
      display: none;
    }
    .topnav.responsive .navBarUL {
      display: block;
      text-align: left;
    }
    .hiddenMobile {
      display: none !important;
    }
    hr {
      margin-top: 0;
      margin-bottom: 0;
    }
    .navBarParent {
      display: block;
    }
    .separator {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .navBarULRight {
      position: static;
    }
    .navBarUL {
      display: flex;
      align-items: center;
      margin: 7.5px 0px;
    }
    .navBarUL li {
      height: 37px;
    }
    .navBarUL li a {
      font-size: 14px;
      padding: 10px 15px;
    }

    .navBarToggle {
      margin-right: 0;
      display: block;
      position: absolute;
      left: 11px;
      top: 15px;
      background: #fff;
    }

    .navBarDefault {
      padding: 20px;
      width: 100%;
      height: 60px;
    }

    .navBarHeader {
      width: 1.3rem;
      line-height: 20px;
      img {
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
      }
    }

    .navBarBrand {
      font-weight: 500;
      font-size: 0.12rem;
      line-height: 0.1rem;
      letter-spacing: 0.165em;
      text-transform: uppercase;
      color: #ffffff;
    }

    .gitBtn {
      display: inline-block;
    }

    .mobileView {
      text-align: left !important;
      padding-left: 0 !important;
    }

    .searchWrapper {
      padding: 0px 0;
      padding-top: 0px;
      position: absolute;
      bottom: 0px;
      width: calc(100% - 70px);
      position: absolute;
      left: 40px;
      top: 8px;
    }
    .hitWrapper {
      width: 100%;
      right: 0;
      top: 35px;
      max-height: fit-content;
      position: static;
    }

    .addPaddTopBottom {
      margin: 0.24rem 0 0;
      padding-top: 0.24rem;
      border-top: 0.01rem solid rgba(0, 0, 0, 0.1);
    }

    .heading1 {
      margin: 0.24rem 0 0.16rem;
      font-size: var(--title1-font-size-m);
      line-height: 0.23rem;
    }

    .heading2 {
      margin: 0.16rem 0 0.06rem;
      font-size: var(--title2-font-size-m);
    }

    .paragraph {
      line-height: 1.4;
      font-size: var(--content-font-size-m);
      color: rgba(0, 0, 0, 0.6);
      img {
        margin: 0.16rem 0;
        border-radius: 0.06rem;
      }
    }

    .unorderedList {
      margin: 0.12rem 0;
      color: rgba(0, 0, 0, 0.6);
      .listItem {
        line-height: 0.26rem;
        font-size: var(--content-font-size-m);
        &::before {
          width: 0.06rem;
          height: 0.06rem;
          background: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .orderedList {
      margin: 0.12rem 0;
      font-size: var(--content-font-size-m);
      color: rgba(0, 0, 0, 0.6);
      .listItem {
        margin: 0.12rem 0 0;
        line-height: 1.6;
        &::before {
          top: -0.04rem;
          left: -0.34rem;
          font-size: var(--content-font-size-m);
        }
      }
    }
  }
`;
