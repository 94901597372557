import * as React from 'react';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';

import ThemeProvider from './theme/themeProvider';
import mdxComponents from './mdxComponents';
import Menu from './Menu';
import Topbar from './topbar';
import Sidebar from './sidebar';
import RightSidebar from './rightSidebar';
import config from '../../config.js';

const TopBarWidth = styled('div')`
  width: 100%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Wrapper = styled('div')`
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: start;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    display: block;
    overflow: unset;
  }

  @media print {
    overflow: unset;
  }
`;

const Content = styled('main')`
  flex-grow: 1;
  padding: 8px var(--side-padding) 49px;
  background-color: #f1f3f9;
  overflow: auto;

  @media only screen and (min-width: 1921px) {
    padding: 8px 120px 49px;
  }

  @media (min-width: 1441px) and (max-width: 1920px) {
    padding: 8px calc(48px + 0.15 * (100vw - 1440px)) 49px;
  }

  @media (min-width: 769px) and (max-width: 1440px) {
    padding: 8px 32px 49px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0.16rem 0.2rem;
    overflow: unset;
  }

  @media print {
    padding: 16px 20px;
    overflow: unset;
  }
`;

const MaxWidth = styled('div')`
  max-width: var(--main-width);

  @media only screen and (max-width: 1440px) {
    max-width: calc(var(--main-width) - 120px);
  }
`;

const LeftSideBarWidth = styled('div')`
  box-sizing: content-box;
  flex-shrink: 0;
  padding: var(--side-padding);
  width: var(--side-width);
  background: #e8ebf2;
  mix-blend-mode: multiply;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 769px) and (max-width: 1280px) {
    padding: var(--side-padding);
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }

  @media print {
    display: none;
  }
`;

const RightSideBarWidth = styled('div')`
  position: absolute;
  top: 0;
  margin-left: 32px;
  max-width: 300px;
  height: 100%;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: 1921px) {
    left: calc(var(--side-width) + var(--side-padding) * 2 + 16px + 120px + var(--main-width));
  }

  @media (min-width: 1441px) and (max-width: 1920px) {
    left: calc(
      var(--side-width) + var(--side-padding) * 2 + 64px + 0.15 * (100vw - 1440px) +
        var(--main-width)
    );
  }

  @media (min-width: 769px) and (max-width: 1440px) {
    left: calc(var(--side-width) + var(--side-padding) * 2 + 32px - 120px + var(--main-width));
  }
`;

let activeMark = null;
const Layout = ({ children, location, lang }) => {
  React.useEffect(() => {
    const BUFFER = 20;
    const contentEl = document.getElementById('content');
    const markListEl = document.getElementById('markList');
    const headList = contentEl.querySelectorAll('[id]:not(h1)');
    let hashchanged = location.hash !== '';
    const mark = function (hash) {
      const markEl =
        markListEl &&
        markListEl.querySelector(`[href="#${hash.replace(/"/g, '\\"').replace(/'/g, "\\'")}"]`);
      if (markEl) {
        if (activeMark) activeMark.classList.remove('active');
        markEl.classList.add('active');
        activeMark = markEl;
      }
      return markEl;
    };
    let nearestEl = headList && headList[0];
    activeMark = nearestEl && mark(nearestEl.id);
    const scrollHandler = function () {
      !hashchanged &&
        headList.forEach((el, index) => {
          if (!nearestEl) return;
          const elOffsetTop = index === 0 ? 0 : el.offsetTop;
          const distance = contentEl.scrollTop - elOffsetTop + BUFFER;
          const nearDistance = contentEl.scrollTop - nearestEl.offsetTop + BUFFER;
          if ((nearDistance < 0 && distance > 0) || (distance > 0 && distance < nearDistance)) {
            nearestEl = el;
            mark(el.id);
          }
        });
    };
    const hashchangeHandler = function (e) {
      hashchanged = true;
      mark(decodeURIComponent(location.hash).replace(/#/g, ''));
      setTimeout(() => {
        hashchanged = false;
      }, 100);
    };
    hashchanged && hashchangeHandler();
    window.addEventListener('hashchange', hashchangeHandler);
    contentEl && markListEl && contentEl.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('hashchange', hashchangeHandler);
      contentEl && contentEl.removeEventListener('scroll', scrollHandler, false);
    };
  });
  return (
    <ThemeProvider location={location}>
      <MDXProvider components={mdxComponents}>
        <Menu location={location} />
        <TopBarWidth>
          <Topbar location={location} />
        </TopBarWidth>
        <Wrapper>
          <LeftSideBarWidth>
            <Sidebar location={location} />
          </LeftSideBarWidth>
          {config.sidebar.title ? (
            <div
              className={'sidebarTitle sideBarShow'}
              dangerouslySetInnerHTML={{ __html: config.sidebar.title }}
            />
          ) : null}
          <Content id="content">
            <MaxWidth>{children}</MaxWidth>
          </Content>
          <RightSideBarWidth id="markList" className={'hiddenMobile'}>
            <RightSidebar location={location} />
          </RightSideBarWidth>
        </Wrapper>
      </MDXProvider>
    </ThemeProvider>
  );
};

export default Layout;
