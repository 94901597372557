import styled from '@emotion/styled';

export const Sidebar = styled('aside')`
  width: 100%;
  min-height: 100%;
  padding-left: 25px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  .rightSideTitle {
    font-size: 10px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    padding: 7px 24px 7px 16px;
    border-left: 1px solid #e6ecf1;
    border-left-color: rgb(230, 236, 241);

    color: ${(props) => props.theme.colors.text};
  }

  .rightSideBarUL {
    padding-top: 16px;
  }

  .rightSideBarUL li {
    list-style-type: none;
  }

  .rightSideBarUL li a {
    padding-right: 24px;
    line-height: 1.4;
  }

  @media only screen and (max-width: 50rem) {
    width: 100%;
    position: relative;
  }
`;

export const ListItem = styled(({ className, active, level, ...props }) => {
  return (
    <li className={className}>
      <a href={props.to}>{props.children}</a>
      {props.items && <ul>{props.items}</ul>}
    </li>
  );
})`
  list-style: none;

  &.mark_level_1 a {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.32px;
    color: rgba(0, 0, 0, 0.8);
  }
  &.mark_level_2 a {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;
    color: rgba(0, 0, 0, 0.6);
  }
  &.mark_level_3 a {
    font-size: 14px;
    font-weight: 400;
  }

  a {
    position: relative;
    display: block;
    color: #5c6975;
    text-decoration: none;

    &:hover {
      color: #3e6cff;
    }

    &.active {
      font-weight: 600 !important;
      color: #3e6cff !important;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: -28px;
        width: 6px;
        height: 18px;
        background-color: #3e6cff;
        border-radius: 3px;
        transform: translateY(-50%);
      }
    }

    ${(props) =>
      props.active &&
      `
      color: #1ED3C6;
      border-color: rgb(230,236,241) !important;
      border-style: solid none solid solid;
      border-width: 1px 0px 1px 1px;
      background-color: #fff;
    `} // external link icon
    svg {
      float: right;
      margin-right: 1rem;
    }
  }
`;
