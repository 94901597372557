import config from '../../config';
import en_us from './en-us.json';
import ja_jp from './ja-jp.json';
import zh_cn from './zh-cn.json';

const languages = () => {
  switch (config.gatsby.lang) {
    case 'en-us':
      return en_us;
    case 'ja-jp':
      return ja_jp;
    case 'zh-cn':
      return zh_cn;
    default:
      return en_us;
  }
};

export default languages();
