import * as React from 'react';
import Link from './link';

import { StyledActions } from './styles/PageNavigationButtons';
import contactImg from './images/contact.svg';
import discordImg from './images/discord.svg';

const Actions = () => {
  return (
    <StyledActions>
      <div className={'previousBtn'}>
        <div className={'leftArrow'}>
          <img src={contactImg} />
        </div>
        <div className={'preRightWrapper'}>
          <div className={'nextPreviousTitle'}>CONTACT US</div>
          <div className={'smallContent'}>
            Still need assistance? <Link to="/contact">WE'RE HERE TO HELP</Link>
          </div>
        </div>
      </div>
      <div className={'nextBtn'}>
        <div className={'rightArrow'}>
          <img src={discordImg} />
        </div>
        <div className={'nextRightWrapper'}>
          <div className={'nextPreviousTitle'}>JOIN THE COMMUNITY</div>
          <div className={'smallContent'}>
            Connect with other VITURE users:&nbsp;
            <a href="https://discord.gg/viture" target="_blank">
              JOIN US ON DISCORD
            </a>
          </div>
        </div>
      </div>
    </StyledActions>
  );
};

export default Actions;
