import { navigate } from 'gatsby';

const ENV = typeof window !== 'undefined' ? window.ENV : process.env;

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This tutorial has been updated. ` + `Reload to display the latest version?`
  );
  if (answer === true) {
    window.location.reload();
  }
};

export const onRouteUpdate = ({ location }) => {
  (location.pathname === '/' ||
    location.pathname === `/${ENV.NODE_ENV_LANGURAGE}` ||
    location.pathname === `/${ENV.NODE_ENV_LANGURAGE}/`) &&
    navigate('/xr_glasses/introduction', { replace: true });
  (location.pathname === '/xr_glasses' ||
    location.pathname === '/xr_glasses/' ||
    location.pathname === `/${ENV.NODE_ENV_LANGURAGE}/xr_glasses` ||
    location.pathname === `/${ENV.NODE_ENV_LANGURAGE}/xr_glasses/`) &&
    navigate('/xr_glasses/introduction', { replace: true });
  (location.pathname === '/neckband' ||
    location.pathname === '/neckband/' ||
    location.pathname === `/${ENV.NODE_ENV_LANGURAGE}/neckband` ||
    location.pathname === `/${ENV.NODE_ENV_LANGURAGE}/neckband/`) &&
    navigate('/neckband/introduction', { replace: true });
  (location.pathname === '/mobile_dock' ||
    location.pathname === '/mobile_dock/' ||
    location.pathname === `/${ENV.NODE_ENV_LANGURAGE}/mobile_dock` ||
    location.pathname === `/${ENV.NODE_ENV_LANGURAGE}/mobile_dock/`) &&
    navigate('/mobile_dock/introduction', { replace: true });
  (location.pathname === '/hdmi_xr_adapter' ||
    location.pathname === '/hdmi_xr_adapter/' ||
    location.pathname === `/${ENV.NODE_ENV_LANGURAGE}/hdmi_xr_adapter` ||
    location.pathname === `/${ENV.NODE_ENV_LANGURAGE}/hdmi_xr_adapter/`) &&
    navigate('/hdmi_xr_adapter/introduction', { replace: true });
  (location.pathname === '/usb_c_xr_charging_adapter' ||
    location.pathname === '/usb_c_xr_charging_adapter/' ||
    location.pathname === `/${ENV.NODE_ENV_LANGURAGE}/usb_c_xr_charging_adapter` ||
    location.pathname === `/${ENV.NODE_ENV_LANGURAGE}/usb_c_xr_charging_adapter/`) &&
    navigate('/usb_c_xr_charging_adapter/introduction', { replace: true });

  var contentEl = document.getElementById('content');
  contentEl && location.hash === '' && (contentEl.scrollTop = 0);
};

export const onClientEntry = () => {
  window.addEventListener('message', function (event) {
    if (event.data.type === 'gorgias-contact-form-loaded') {
      event.source.postMessage(
        {
          type: 'gorgias-parent-url',
          parentUrl: window.location.href,
        },
        '*'
      );
    }
  });
};
