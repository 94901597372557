import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// import Link from './link';
import config from '../../config';
import { Sidebar, ListItem } from './styles/Sidebar';

const getItems = (items, level) => {
  return items ? items.map((innerItem, index) => {
    const itemId = innerItem.title
      ? innerItem.title.replace(/\s+/g, '').toLowerCase()
      : '#';

    if (innerItem.items && level < 2) {
      return (
        <ListItem key={index} to={`#${itemId}`} level={level} className={`mark_level_${level}`} items={getItems(innerItem.items, level + 1)}>
          {innerItem.title}
        </ListItem>
      );
    }

    return (
      <ListItem key={index} to={`#${itemId}`} level={level} className={`mark_level_${level}`}>
        {innerItem.title}
      </ListItem>
    );
  }) : [];
};

const SidebarLayout = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                title
                slug
              }
              tableOfContents
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      let finalNavItems;

      if (allMdx.edges !== undefined && allMdx.edges.length > 0) {
        allMdx.edges.map(item => {
          if (item !== undefined) {
            if (
              location.pathname.indexOf(item.node.fields.slug) === 0 ||
              location.pathname.indexOf(config.gatsby.pathPrefix + item.node.fields.slug) === 0
            ) {
              if (item.node.tableOfContents.items && item.node.tableOfContents.items.length) {
                finalNavItems = getItems(item.node.tableOfContents.items[0].items, 1);
              }
            }
          }
        });
      }

      if (finalNavItems && finalNavItems.length) {
        return (
          <Sidebar>
            <ul className={'rightSideBarUL'}>
              {finalNavItems}
            </ul>
          </Sidebar>
        );
      }
    }}
  />
);

export default SidebarLayout;
