import * as React from 'react';
import OpenedSvg from '../images/opened';
import ClosedSvg from '../images/closed';
import config from '../../../config';
import Link from '../link';

const TreeNode = ({ className = '', setCollapsed, collapsed, url, title, items, ...rest }) => {
  const isCollapsed = collapsed[url];
  const collapse = () => {
    setCollapsed(url);
  };

  const hasChildren = items.length !== 0;

  let location;

  if (typeof document != 'undefined') {
    location = document.location;
  }

  const [calculatedClassName, setCalculatedClassName] = React.useState(`${className} item`);
  React.useEffect(() => {
    const active =
      location &&
      (location.pathname === url ||
        location.pathname === url + '/' ||
        location.pathname === config.gatsby.pathPrefix + url ||
        location.pathname === config.gatsby.pathPrefix + url + '/');
    setCalculatedClassName(`${className} item ${active ? 'active' : ''}`);
  });

  return (
    <li className={calculatedClassName}>
      {title && (
        <>
          {!hasChildren ? <Link to={url}>{title}</Link> : null}
          {!config.sidebar.frontLine && title && hasChildren ? (
            <div className="directory" onClick={collapse}>
              {title}
              <button
                aria-label="collapse"
                className={isCollapsed ? 'collapser closed' : 'collapser'}
              >
                <OpenedSvg />
              </button>
            </div>
          ) : null}
        </>
      )}

      {!isCollapsed && hasChildren ? (
        <ul>
          {items.map((item, index) => (
            <TreeNode
              key={item.url + index.toString()}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              {...item}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default TreeNode;
