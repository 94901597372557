import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';

import { Layout } from '$components';
import Actions from '../components/Actions';
import config from '../../config';
import { StyledMainWrapper } from '../components/styles/Docs';
import lang from '../langurages';

import xr_glasses from '../components/images/xr_glasses.png';
import neckband from '../components/images/neckband.png';
import mobile_dock from '../components/images/mobile_dock.png';
import hdmi_xr_adapter from '../components/images/hdmi_xr_adapter.png';
import usb_c_xr_charging_adapter from '../components/images/usb_c_xr_charging_adapter.png';
import xr_glasses_m from '../components/images/xr_glasses_m.png';
import neckband_m from '../components/images/neckband_m.png';
import mobile_dock_m from '../components/images/mobile_dock_m.png';
import hdmi_xr_adapter_m from '../components/images/hdmi_xr_adapter_m.png';
import usb_c_xr_charging_adapter_m from '../components/images/usb_c_xr_charging_adapter_m.png';

const forcedNavOrder = config.sidebar.forcedNavOrder;
const pageActions = config.sidebar.pageActions;

export default class MDXRuntimeTest extends Component {
  componentDidUpdate() {
    document.body.classList.remove('dark');
  }
  componentDidMount() {
    document.body.classList.remove('dark');
  }
  render() {
    const { data } = this.props;

    if (!data || !data.site || !data.site.siteMetadata) {
      return this.props.children;
    }
    const {
      allMdx,
      mdx,
      site: {
        siteMetadata: { docsLocation, title },
      },
    } = data;

    const githubIcon = require('../components/images/github.svg').default;
    const navItems = allMdx.edges
      .map(({ node }) => node.fields.slug)
      .filter((slug) => slug !== '/')
      .sort()
      .reduce(
        (acc, cur) => {
          if (forcedNavOrder.find((url) => url === cur)) {
            return { ...acc, [cur]: [cur] };
          }

          let prefix = cur.split('/')[1];

          if (config.gatsby && config.gatsby.trailingSlash) {
            prefix = prefix + '/';
          }

          if (prefix && forcedNavOrder.find((url) => url === `/${prefix}`)) {
            return { ...acc, [`/${prefix}`]: [...acc[`/${prefix}`], cur] };
          } else {
            return { ...acc, items: [...acc.items, cur] };
          }
        },
        { items: [] }
      );

    const nav = forcedNavOrder
      .reduce((acc, cur) => {
        return acc.concat(navItems[cur]);
      }, [])
      .concat(navItems.items)
      .map((slug) => {
        if (slug) {
          const { node } = allMdx.edges.find(({ node }) => node.fields.slug === slug);

          return { title: node.fields.title, url: node.fields.slug };
        }
      });

    // meta tags
    const metaTitle = mdx.frontmatter.metaTitle || config.siteMetadata.title;

    const metaDescription = mdx.frontmatter.metaDescription || config.siteMetadata.description;

    let canonicalUrl = config.gatsby.siteUrl;

    canonicalUrl =
      config.gatsby.pathPrefix !== '/' ? canonicalUrl + config.gatsby.pathPrefix : canonicalUrl;
    canonicalUrl = canonicalUrl + mdx.fields.slug;

    const banners = {
      xr_glasses,
      neckband,
      mobile_dock,
      hdmi_xr_adapter,
      usb_c_xr_charging_adapter,
      xr_glasses_m,
      neckband_m,
      mobile_dock_m,
      hdmi_xr_adapter_m,
      usb_c_xr_charging_adapter_m,
    };

    const bannerImage = banners[mdx.fields.slug.split('/')[1].toLowerCase()];
    const bannerImageM = banners[mdx.fields.slug.split('/')[1].toLowerCase() + '_m'];

    return (
      <Layout {...this.props} lang={lang}>
        <Helmet>
          {metaTitle ? <title>{metaTitle}</title> : null}
          {metaTitle ? <meta name="title" content={metaTitle} /> : null}
          {metaDescription ? <meta name="description" content={metaDescription} /> : null}
          {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
          {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
          {metaTitle ? <meta property="twitter:title" content={metaTitle} /> : null}
          {metaDescription ? (
            <meta property="twitter:description" content={metaDescription} />
          ) : null}
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <div className={'titleWrapper'}>
          {bannerImage && (
            <img
              src={bannerImage}
              srcSet={`${bannerImageM} 776w, ${bannerImage} 2x, ${bannerImageM} 3x, ${bannerImage} 1800w`}
              sizes="(max-width: 768px) 776w, 1800w"
            />
          )}
        </div>
        <StyledMainWrapper>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </StyledMainWrapper>
        {pageActions && (
          <div className={'addPaddTopBottom'}>
            <Actions />
          </div>
        )}
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        title
        docsLocation
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        metaTitle
        metaDescription
      }
    }
    allMdx {
      edges {
        node {
          fields {
            slug
            title
          }
        }
      }
    }
  }
`;
